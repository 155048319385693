.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.imageContainer {
    max-width: 75vw;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.App-header {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: rgb(112, 76, 182);
}

/*.container:hover {*/
/*    cursor: pointer;*/
/*}*/

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.light .MuiSvgIcon-root {
    color: rgb(101, 163, 13);
}

.light button.bg-lime-600 .MuiSvgIcon-root {
    color: white;
}

.light .MuiSvgIcon-root.settings {
    color: rgb(27, 42, 7);
}

.light .MuiSvgIcon-root.active {
    color: rgb(54, 84, 37);
}

.MuiSvgIcon-root.small {
    width: 1em;
    height: 1em;
}

.dark .MuiSvgIcon-root {
    color: rgb(190, 242, 100);
}

.dark .MuiSvgIcon-root.active {
    color: rgb(75, 255, 56);
}

.MuiStepLabel-root .MuiStepLabel-labelContainer .MuiSvgIcon-root,
.dark .MuiStepLabel-labelContainer .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.6);
}

.dark .MuiInputBase-root {
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.dark .MuiInputAdornment-root p {
    color: rgb(209, 213, 219);
}

.pac-container {
    z-index: 10000;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
}

.light input:-webkit-autofill,
.light input:-webkit-autofill:hover,
.light input:-webkit-autofill:focus,
.light input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(255, 255, 255) inset !important;
}

.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(55, 65, 81) inset !important;
    -webkit-text-fill-color: white;
}

input[type='file']:hover + label > div {
    opacity: 0.6;
    cursor: pointer;
}

.MuiCardHeader-content {
    width: calc(100% - 40px);
}

.MuiCardHeader-avatar + .MuiCardHeader-content {
    width: calc(100% - 80px);
}

@media (max-width: 1280px) {
    .skeleton > .MuiSkeleton-root {
        min-height: 4rem;
    }
}

.MuiAvatar-root.component {
    fill: rgb(27, 42, 7);
    background-color: white;
    border: solid 1px #cfcfcf;
}

button.animation-pulse {
    background-color: rgb(101, 163, 13);
    color: white;
    animation: pulse 1.5s infinite;
}

.tariff-example-container .tariff-example-table-container {
    box-shadow: none;
}

.tariff-example tr,
.tariff-example thead {
    border: 0;
}

.tariff-example thead td {
    color: #414141;
}

.tariff-example .table-header {
    color: #000000;
}

.tariff-example .light-yellow-row {
    background-color: #ffffa7;
}

.tariff-example .yellow-row {
    background-color: #ffe9a6;
}

.cash-flow-chart .apexcharts-legend-series {
    padding: 5px 65px;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgb(101, 163, 13);
    }

    50% {
        box-shadow: 0 0 0 10px rgba(229, 62, 62, 0);
    }
}
